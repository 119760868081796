var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    on: {
      "click:outside": _vm.closeDialog,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    model: {
      value: _vm.dialogVisible,
      callback: function ($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  }, [_c('v-card', {
    staticClass: "py-6 px-10"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center mb-8"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.purpose === "create" ? "Create" : "Edit") + " Wiki")]), _c('v-icon', {
    attrs: {
      "color": "black"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "rules": [].concat(_vm.validator.wikiTitle()),
      "label": "Name",
      "color": "orange",
      "counter": "30",
      "clearable": "",
      "required": "",
      "outlined": ""
    },
    model: {
      value: _vm.payloadData.name,
      callback: function ($$v) {
        _vm.$set(_vm.payloadData, "name", $$v);
      },
      expression: "payloadData.name"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.tenants,
      "label": "Tenants",
      "color": "orange",
      "multiple": "",
      "clearable": "",
      "outlined": "",
      "item-text": "name",
      "item-value": "id"
    },
    on: {
      "change": _vm.ensureAlwaysSelected
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < _vm.visibleChipLength ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === _vm.visibleChipLength ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.payloadData.selectedTenants.length - _vm.visibleChipLength) + " more) ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.payloadData.selectedTenants,
      callback: function ($$v) {
        _vm.$set(_vm.payloadData, "selectedTenants", $$v);
      },
      expression: "payloadData.selectedTenants"
    }
  }), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": _vm.purpose === 'create' ? 'success' : 'accent',
      "large": "",
      "width": "100%"
    },
    on: {
      "click": _vm.modalAction
    }
  }, [_vm._v(" " + _vm._s(_vm.purpose) + " ")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }