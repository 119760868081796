var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "loading": _vm.loading,
      "elevation": "2"
    }
  }, [_c('div', {
    staticClass: "pa-5"
  }, [_vm.isAdmin ? _c('div', {
    class: ['d-flex', 'justify-sm-end', 'align-center', {
      'mb-5': _vm.nodes.length
    }]
  }, [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.loading,
            "color": "success"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Create Content ")], 1)];
      }
    }], null, false, 845348308)
  }, [_c('CreateDropdown', {
    attrs: {
      "createOptions": _vm.createOptions
    },
    on: {
      "action": _vm.handleAction
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "main-list"
  }, [_vm.nodes.length ? _c('CollectionList', {
    attrs: {
      "nodes": _vm.nodes,
      "loading": _vm.loading
    },
    on: {
      "create": _vm.handleAction,
      "delete": _vm.handleDelete,
      "save": _vm.handleSave
    }
  }) : _c('div', {
    class: ['text-center', 'font-weight-bold', {
      'pa-4': _vm.isAdmin
    }]
  }, [_vm._v(" There is no content for this wiki to show. ")]), _c('CollectionsModal', {
    attrs: {
      "value": _vm.nodeModal,
      "modalOptions": _vm.modalOptions,
      "loading": _vm.loading
    },
    on: {
      "create": _vm.handleModalCreate,
      "close": function ($event) {
        return _vm.handleDialog(false);
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }