var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.pageLoading ? _c('div', [_c('DetailHeader', {
    attrs: {
      "wiki": _vm.wikiData,
      "editDialog": _vm.editDialog,
      "loading": _vm.loading
    },
    on: {
      "edit": _vm.updateWiki,
      "delete": _vm.deleteWiki,
      "dialog": _vm.handleWikiDialog
    }
  }), _c('DetailCollections', {
    attrs: {
      "nodes": _vm.wikiData.nodes,
      "nodeModal": _vm.nodeModal,
      "loading": _vm.loading
    },
    on: {
      "create": _vm.createNode,
      "delete": _vm.deleteNode,
      "dialog": _vm.handleNodeDialog,
      "update": _vm.updateNode
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }