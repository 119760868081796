var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "tenants": _vm.tenants,
      "createDialog": _vm.createDialog,
      "loading": _vm.loading
    },
    on: {
      "dialog": _vm.handleDialog,
      "create": _vm.createWiki
    }
  }), _c('div', {
    staticClass: "mt-5"
  }, [_c('Tabs', {
    attrs: {
      "wikis": _vm.wikis,
      "loading": _vm.loading,
      "tenants": _vm.tenants
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }