import { render, staticRenderFns } from "./CollectionsModal.vue?vue&type=template&id=991974fe&scoped=true"
import script from "./CollectionsModal.vue?vue&type=script&lang=js"
export * from "./CollectionsModal.vue?vue&type=script&lang=js"
import style0 from "./CollectionsModal.vue?vue&type=style&index=0&id=991974fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "991974fe",
  null
  
)

export default component.exports