var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isAdmin ? _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "2"
    }
  }, [_c('div', {
    staticClass: "header-container"
  }, [_c('div', {
    staticClass: "title-container secondary--text"
  }, [_c('span', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.wiki.name))]), _vm.wiki.tenantIds && _vm.wiki.tenantIds.length ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "vertical-divider"
  }), _c('div', {
    staticClass: "tenants-container"
  }, _vm._l(_vm.filteredTenants, function (tenant, idx) {
    return _c('v-tooltip', {
      key: idx,
      attrs: {
        "bottom": "",
        "color": "black"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-avatar', _vm._g(_vm._b({
            staticClass: "mr-1",
            attrs: {
              "width": "20",
              "height": "20",
              "tile": "",
              "min-width": "30"
            }
          }, 'v-avatar', attrs, false), on), [_c('img', {
            attrs: {
              "src": `https://assets.trainplanet.com/wlabel/${_vm.fixUrl(tenant.name)}/logos/favicon-32x32.png`,
              "alt": "Tenant Image"
            }
          })])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(tenant.name))])]);
  }), 1)]) : _vm._e()]), _vm.isAdmin ? _c('div', {
    staticClass: "button-container"
  }, _vm._l(_vm.bodyButtons, function (button, idx) {
    return _c('v-btn', {
      key: idx,
      attrs: {
        "color": button.color,
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.handleAction(button.action);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(button.icon))])], 1);
  }), 1) : _vm._e()]), _c('WikiModal', {
    attrs: {
      "value": _vm.editDialog,
      "loading": _vm.loading,
      "tenants": _vm.tenants,
      "payload": _vm.modalPayload,
      "purpose": "edit"
    },
    on: {
      "close": _vm.handleClose,
      "action": _vm.handleEdit
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }