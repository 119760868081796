var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    class: ['collections-list', `depth-${_vm.depth}`],
    attrs: {
      "expand": _vm.depth !== 0
    }
  }, [_vm._l(_vm.nodesWithEditing, function (node, idx) {
    return [node.type === 'collection' ? _c('v-list-group', {
      key: `collection-${node.id}`,
      class: ['nested-list-group', `depth-${_vm.depth}`, {
        contentless: !node.subNodes.length
      }],
      attrs: {
        "disabled": node.isEditing || _vm.isEditingNodeLocal && _vm.depth === 0,
        "ripple": !node.isEditing,
        "value": node.isExpanded
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "d-flex gap-10 font-weight-bold"
          }, [node.isEditing ? _c('v-text-field', {
            staticClass: "position-input",
            attrs: {
              "value": _vm.position,
              "type": "number",
              "label": "Position",
              "outlined": "",
              "dense": "",
              "hide-details": ""
            },
            on: {
              "input": _vm.updatePosition
            }
          }) : _vm._e(), _c('v-text-field', {
            class: ['text-field', {
              'title-view': !node.isEditing
            }],
            attrs: {
              "value": node.isEditing ? _vm.title : node.title,
              "readonly": !node.isEditing,
              "disabled": !node.isEditing,
              "outlined": node.isEditing,
              "label": "Title",
              "dense": "",
              "hide-details": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
              },
              "input": _vm.updateTitle
            }
          })], 1)], 1), _c('v-list-item-icon', {
            staticClass: "mr-0"
          }, [!node.isEditing ? _c('v-tooltip', {
            attrs: {
              "content-class": !_vm.isEditingNodeLocal ? 'empty-tooltip' : '',
              "bottom": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs;
                return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm.isAdmin && !node.isEditing ? _c('v-menu', {
                  attrs: {
                    "offset-y": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function (_ref2) {
                      var on = _ref2.on,
                        attrs = _ref2.attrs;
                      return [_c('v-btn', _vm._g(_vm._b({
                        attrs: {
                          "disabled": _vm.isEditingNodeLocal || _vm.loading,
                          "icon": "",
                          "color": "success"
                        }
                      }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
                    }
                  }], null, true)
                }, [_c('CreateDropdown', {
                  attrs: {
                    "createOptions": _vm.createOptions,
                    "isSub": ""
                  },
                  on: {
                    "action": function ($event) {
                      return _vm.handleCreate(node.id, node.title, $event);
                    }
                  }
                })], 1) : _vm._e(), _vm.isAdmin && !node.isEditing ? _c('v-btn', {
                  attrs: {
                    "disabled": _vm.isEditingNodeLocal || _vm.loading,
                    "color": "third",
                    "icon": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                      return _vm.setEditState(node, true);
                    }
                  }
                }, [_c('v-icon', {
                  attrs: {
                    "size": "20"
                  }
                }, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.isAdmin && !node.isEditing ? _c('v-btn', {
                  attrs: {
                    "disabled": _vm.isEditingNodeLocal || _vm.loading,
                    "color": "primary",
                    "icon": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                      return _vm.handleDelete(node.id, node.type);
                    }
                  }
                }, [_c('v-icon', {
                  attrs: {
                    "size": "20"
                  }
                }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)];
              }
            }], null, true)
          }, [_c('span', [_vm._v("You cannot perform other actions while editing a node")])]) : _vm._e(), node.isEditing ? _c('v-btn', {
            staticClass: "mr-2",
            attrs: {
              "color": "success"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.handleSave(node);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "20",
              "left": ""
            }
          }, [_vm._v("mdi-check")]), _vm._v(" Save ")], 1) : _vm._e(), node.isEditing ? _c('v-btn', {
            attrs: {
              "color": "primary"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.setEditState(node, false);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "20",
              "left": ""
            }
          }, [_vm._v("mdi-close")]), _vm._v(" Cancel ")], 1) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, [node.subNodes && node.subNodes.length ? _c('CollectionList', {
      staticClass: "nested-list",
      attrs: {
        "nodes": node.subNodes,
        "depth": _vm.depth + 1,
        "isEditingNode": _vm.isEditingNodeLocal,
        "loading": _vm.loading
      },
      on: {
        "update:isEditingNode": _vm.handleIsEditingNodeUpdate,
        "create": function ($event) {
          return _vm.$emit('create', $event);
        },
        "delete": function ($event) {
          return _vm.$emit('delete', $event);
        },
        "save": function ($event) {
          return _vm.$emit('save', $event);
        }
      }
    }) : _vm._e()], 1) : node.type === 'item' ? _c('v-list-group', {
      key: `item-${node.id}${_vm.depth === 0 && idx === 0 ? '-' + _vm.firstNodeExpanded : ''}`,
      class: ['nested-list-item', `depth-${_vm.depth}`, {
        contentless: !node.content && !node.isEditing
      }],
      attrs: {
        "value": node.isExpanded,
        "disabled": node.isEditing || _vm.isEditingNodeLocal && _vm.depth === 0,
        "ripple": !node.isEditing
      },
      on: {
        "click": function ($event) {
          return _vm.handleFirstNode(node);
        }
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "d-flex gap-10 font-weight-bold"
          }, [node.isEditing ? _c('v-text-field', {
            staticClass: "position-input",
            attrs: {
              "value": _vm.position,
              "type": "number",
              "label": "Position",
              "outlined": "",
              "dense": "",
              "hide-details": ""
            },
            on: {
              "input": _vm.updatePosition
            }
          }) : _vm._e(), _c('v-text-field', {
            class: ['text-field', {
              'title-view': !node.isEditing
            }],
            attrs: {
              "value": node.isEditing ? _vm.title : node.title,
              "readonly": !node.isEditing,
              "disabled": !node.isEditing,
              "outlined": node.isEditing,
              "label": "Title",
              "dense": "",
              "hide-details": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
              },
              "input": _vm.updateTitle
            }
          })], 1)], 1), _c('v-list-item-icon', {
            staticClass: "mr-0"
          }, [!node.isEditing ? _c('v-tooltip', {
            attrs: {
              "content-class": !_vm.isEditingNodeLocal ? 'empty-tooltip' : '',
              "bottom": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref3) {
                var on = _ref3.on,
                  attrs = _ref3.attrs;
                return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm.isAdmin && !node.isEditing ? _c('v-btn', {
                  attrs: {
                    "disabled": _vm.isEditingNodeLocal || _vm.loading,
                    "color": "third",
                    "icon": ""
                  }
                }, [_c('v-icon', {
                  attrs: {
                    "size": "20"
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                      return _vm.setEditState(node, true);
                    }
                  }
                }, [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.isAdmin && !node.isEditing ? _c('v-btn', {
                  attrs: {
                    "disabled": _vm.isEditingNodeLocal || _vm.loading,
                    "color": "primary",
                    "icon": ""
                  }
                }, [_c('v-icon', {
                  attrs: {
                    "size": "20"
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                      return _vm.handleDelete(node.id, node.type);
                    }
                  }
                }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)];
              }
            }], null, true)
          }, [_c('span', [_vm._v("You cannot perform other actions while editing a node")])]) : _vm._e(), node.isEditing ? _c('v-btn', {
            staticClass: "mr-2",
            attrs: {
              "color": "success"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.handleSave(node);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "20",
              "left": ""
            }
          }, [_vm._v("mdi-check")]), _vm._v(" Save ")], 1) : _vm._e(), node.isEditing ? _c('v-btn', {
            attrs: {
              "color": "primary"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.setEditState(node, false);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "20",
              "left": ""
            }
          }, [_vm._v("mdi-close")]), _vm._v(" Cancel ")], 1) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, [node.content || node.isEditing ? _c('v-list-item', [_c('v-list-item-content', [_c('VueEditor', {
      class: {
        'view-state': !node.isEditing
      },
      attrs: {
        "value": _vm.editorContent(node),
        "disabled": !node.isEditing
      },
      on: {
        "input": _vm.updateContent
      }
    })], 1)], 1) : _vm._e()], 1) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }