<template>
  <v-dialog v-model="dialogVisible" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="600">
    <v-card class="py-6 px-10">
      <div class="d-flex justify-space-between align-center mb-8">
        <div class="text-h5 font-weight-medium">{{ purpose === "create" ? "Create" : "Edit" }} Wiki</div>
        <v-icon @click="closeDialog" color="black">mdi-close</v-icon>
      </div>
      <v-form v-model="valid" ref="form">
        <div>
          <v-text-field
            v-model="payloadData.name"
            :rules="[...validator.wikiTitle()]"
            label="Name"
            color="orange"
            class="mb-2"
            counter="30"
            clearable
            required
            outlined />
          <v-select
            v-model="payloadData.selectedTenants"
            @change="ensureAlwaysSelected"
            :items="tenants"
            label="Tenants"
            color="orange"
            multiple
            clearable
            outlined
            item-text="name"
            item-value="id">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < visibleChipLength">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === visibleChipLength" class="grey--text text-caption">
                (+{{ payloadData.selectedTenants.length - visibleChipLength }} more)
              </span>
            </template>
          </v-select>
          <v-btn
            @click="modalAction"
            :loading="loading"
            :color="purpose === 'create' ? 'success' : 'accent'"
            large
            width="100%">
            {{ purpose }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { validator } from "@/util/helpers";

export default {
  name: "WikiModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tenants: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({
        name: "",
        selectedTenants: ["6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9"],
      }),
    },
    purpose: {
      type: String,
      default: "create",
      validator: (value) => ["create", "edit"].includes(value),
    },
  },
  data() {
    return {
      validator,
      dialogVisible: this.value,
      screenWidth: window.innerWidth,
      valid: true,
      payloadData: {},
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
  },
  watch: {
    payload: {
      handler(newPayload) {
        this.payloadData = Object.assign({}, newPayload);
      },
      deep: true,
      immediate: true,
    },
    value(val) {
      this.dialogVisible = val;
      if (!val) {
        this.$nextTick(() => {
          this.payloadData = Object.assign({}, this.payload);
        });
      }
      this.ensureAlwaysSelected(this.payloadData.selectedTenants);
    },
  },
  computed: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 620:
          return 3;
        case this.screenWidth >= 500:
          return 2;
        default:
          return 1;
      }
    },
  },
  methods: {
    ensureAlwaysSelected(selectedItems) {
      const trainplanetId = "6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9";
      if (!selectedItems.length) {
        this.payloadData.selectedTenants.push(trainplanetId);
      }
    },
    clearModal() {
      this.payloadData = Object.assign({}, this.payload);
      this.$refs.form.reset();
    },
    closeDialog() {
      this.$emit("close", false);
      this.clearModal();
    },
    modalAction() {
      if (this.$refs.form.validate()) {
        this.$emit("action", this.payloadData);
      }
    },
  },
};
</script>
