<template>
  <v-card v-if="!loading" elevation="2">
    <div class="header-container py-5 px-8">
      <img class="float-left" src="https://design.trainplanet.com/images/trainplanet-com_black.svg" width="250px" />
      <div v-if="isAdmin" class="create-button-container">
        <v-btn
          @click="$emit('dialog', true)"
          :disabled="loading || !isAdmin"
          class="create-button"
          color="success"
          large>
          <v-icon left>mdi-plus</v-icon>
          Create Wiki
        </v-btn>
      </div>
    </div>

    <WikiModal
      @close="handleClose"
      @action="handleCreate"
      :value="createDialog"
      :loading="loading"
      :tenants="tenants" />
  </v-card>
</template>

<script>
import WikiModal from "./WikiModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {
    WikiModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    createDialog: {
      type: Boolean,
      default: false,
    },
    tenants: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalModelValue: { ...this.modelValue },
      selectedTenants: [],
      search: null,
    };
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.internalModelValue = { ...newValue };
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 1800:
          return 4;
        case this.screenWidth >= 1600:
          return 3;
        case this.screenWidth >= 1400:
          return 2;
        case this.screenWidth >= 1200:
          return 1;
        case this.screenWidth >= 760:
          return 2;
        default:
          return 1;
      }
    },
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
  },
  methods: {
    handleClose(value) {
      this.$emit("dialog", value);
    },
    handleCreate(payload) {
      this.$emit("create", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1240px) {
    flex-direction: column;

    .create-button-container {
      width: 100%;

      .create-button {
        width: 100%;
      }
    }
  }
}
</style>
