var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', _vm._l(_vm.createOptions, function (option, idx) {
    return _c('v-list-item', {
      key: idx,
      staticClass: "rounded-0",
      on: {
        "click": function ($event) {
          return _vm.handleAction(option.action);
        }
      }
    }, [_c('v-list-item-title', [_c('v-icon', {
      attrs: {
        "size": "20",
        "color": option.color,
        "left": ""
      }
    }, [_vm._v(_vm._s(option.icon))]), _vm._v(" " + _vm._s(option.text) + " ")], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }