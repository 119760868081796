var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": _vm.loading,
      "max-width": _vm.modalData.type === 'collection' ? '600' : '1000'
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    model: {
      value: _vm.dialogVisible,
      callback: function ($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  }, [_c('v-card', {
    staticClass: "py-6 px-10"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center mb-8"
  }, [_c('span', {
    staticClass: "modal-title text-h5 font-weight-medium",
    domProps: {
      "innerHTML": _vm._s(_vm.modalTitle)
    }
  }), _c('v-icon', {
    attrs: {
      "color": "black"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    key: 'position-field-' + _vm.dialogVisible,
    attrs: {
      "loading": _vm.loading,
      "label": "Position",
      "color": "orange",
      "type": "number",
      "outlined": ""
    },
    model: {
      value: _vm.positionNumber,
      callback: function ($$v) {
        _vm.positionNumber = $$v;
      },
      expression: "positionNumber"
    }
  }), _c('v-text-field', {
    key: 'title-field-' + _vm.dialogVisible,
    attrs: {
      "rules": [].concat(_vm.validator.required()),
      "loading": _vm.loading,
      "label": "Title",
      "color": "orange",
      "clearable": "",
      "required": "",
      "outlined": ""
    },
    model: {
      value: _vm.payloadData.title,
      callback: function ($$v) {
        _vm.$set(_vm.payloadData, "title", $$v);
      },
      expression: "payloadData.title"
    }
  }), _vm.modalData.type === 'item' ? _c('VueEditor', {
    key: 'vue-editor-' + _vm.dialogVisible,
    attrs: {
      "disabled": _vm.loading,
      "placeholder": "Content"
    },
    model: {
      value: _vm.payloadData.content,
      callback: function ($$v) {
        _vm.$set(_vm.payloadData, "content", $$v);
      },
      expression: "payloadData.content"
    }
  }) : _vm._e(), _c('v-btn', {
    class: _vm.modalData.type === 'item' ? 'mt-8' : '',
    attrs: {
      "loading": _vm.loading,
      "color": "success",
      "large": "",
      "width": "100%"
    },
    on: {
      "click": _vm.modalAction
    }
  }, [_vm._v(" Create ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }