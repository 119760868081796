var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('v-card', {
    attrs: {
      "elevation": "2"
    }
  }, [_c('div', {
    staticClass: "header-container py-5 px-8"
  }, [_c('img', {
    staticClass: "float-left",
    attrs: {
      "src": "https://design.trainplanet.com/images/trainplanet-com_black.svg",
      "width": "250px"
    }
  }), _vm.isAdmin ? _c('div', {
    staticClass: "create-button-container"
  }, [_c('v-btn', {
    staticClass: "create-button",
    attrs: {
      "disabled": _vm.loading || !_vm.isAdmin,
      "color": "success",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('dialog', true);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Create Wiki ")], 1)], 1) : _vm._e()]), _c('WikiModal', {
    attrs: {
      "value": _vm.createDialog,
      "loading": _vm.loading,
      "tenants": _vm.tenants
    },
    on: {
      "close": _vm.handleClose,
      "action": _vm.handleCreate
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }