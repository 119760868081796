<template>
  <v-card v-if="isAdmin" class="pa-5" elevation="2">
    <div class="header-container">
      <div class="title-container secondary--text">
        <span class="text-h4 font-weight-bold">{{ wiki.name }}</span>
        <div v-if="wiki.tenantIds && wiki.tenantIds.length" class="d-flex align-center">
          <div class="vertical-divider" />
          <div class="tenants-container">
            <v-tooltip v-for="(tenant, idx) in filteredTenants" :key="idx" bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar width="20" height="20" tile min-width="30" class="mr-1" v-bind="attrs" v-on="on">
                  <img
                    :src="`https://assets.trainplanet.com/wlabel/${fixUrl(tenant.name)}/logos/favicon-32x32.png`"
                    alt="Tenant Image" />
                </v-avatar>
              </template>
              <span>{{ tenant.name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div v-if="isAdmin" class="button-container">
        <v-btn
          v-for="(button, idx) in bodyButtons"
          @click="handleAction(button.action)"
          :key="idx"
          :color="button.color"
          icon>
          <v-icon>{{ button.icon }}</v-icon>
        </v-btn>
      </div>
    </div>
    <WikiModal
      @close="handleClose"
      @action="handleEdit"
      :value="editDialog"
      :loading="loading"
      :tenants="tenants"
      :payload="modalPayload"
      purpose="edit" />
  </v-card>
</template>

<script>
import WikiModal from "../Header/WikiModal.vue";
import { mapGetters } from "vuex";
import { fixUrl } from "@/util/helpers";

export default {
  name: "DetailHeader",
  components: { WikiModal },
  props: {
    wiki: {
      type: Object,
      default: () => ({}),
    },
    editDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bodyButtons: [
        {
          icon: "mdi-pencil",
          action: "edit",
          color: "third",
        },
        {
          icon: "mdi-delete",
          action: "delete",
          color: "primary",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
      userRoles: "auth/userRoles",
    }),
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
    modalPayload() {
      return {
        name: this.wiki.name,
        description: this.wiki.description,
        selectedTenants: this.wiki.tenantIds,
      };
    },
    filteredTenants() {
      const matchedTenants = this.tenants.filter((tenant) => this.wiki.tenantIds.includes(tenant.id));
      return matchedTenants;
    },
  },
  methods: {
    fixUrl,
    handleClose() {
      this.$emit("dialog", false);
    },
    handleEdit(payload) {
      this.$emit("edit", payload);
    },
    handleAction(action) {
      switch (action) {
        case "edit":
          this.$emit("dialog", true);
          break;
        case "delete":
          this.$emit("delete");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-container {
    display: flex;
    gap: 10px;
  }
}

.vertical-divider {
  width: 1px;
  height: 40px;
  background-color: #ccc;
  margin: 0 20px;
}

.empty-tooltip {
  display: none;
}

.title-container {
  display: flex;
  align-items: center;
}

@media (max-width: 1240px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .vertical-divider {
    display: none;
  }

  .tenants-container {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }
}

@media (max-width: 958px) {
  .header-container {
    flex-direction: column;

    .title-container {
      width: 100%;
      align-items: center;
    }

    .button-container {
      width: 100%;
      justify-content: center;
      margin-top: 20px;

      .btn {
        flex: 1;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .button-container {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
