<template>
  <v-list>
    <v-list-item
      v-for="(option, idx) in createOptions"
      :key="idx"
      @click="handleAction(option.action)"
      class="rounded-0">
      <v-list-item-title>
        <v-icon size="20" :color="option.color" left>{{ option.icon }}</v-icon>
        {{ option.text }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "CreateDropdown",
  props: {
    createOptions: {
      type: Array,
      required: true,
    },
    isSub: {
      type: Boolean,
      default: false,
    },
    purpose: {
      type: String,
      default: "create",
    },
  },
  methods: {
    handleAction(action) {
      this.$emit("action", { type: action, isSub: this.isSub, purpose: this.purpose });
    },
  },
};
</script>
