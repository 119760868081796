<template>
  <div>
    <v-card :loading="loading" :class="{ 'mb-5': isAdmin }" elevation="2">
      <v-tabs
        v-if="!loading && wikis.length"
        v-model="tab"
        @change="handleTabChange"
        slider-color="transparent"
        color="primary"
        show-arrows>
        <v-tab v-for="wiki in wikis" :key="wiki.id" :href="`#${wiki.id}`" class="tabs">
          {{ wiki.name }}
        </v-tab>
      </v-tabs>
      <div v-if="!wikis.length && !loading" class="text-center font-weight-bold py-5">There is no wiki to show.</div>
    </v-card>
    <v-tabs-items v-model="tab" class="tabs-items" touchless>
      <v-tab-item v-for="wiki in wikis" :key="wiki.id" :value="wiki.id" class="px-2">
        <WikiDetail :wiki="wiki" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WikiDetail from "../WikiDetail.vue";

export default {
  name: "Tabs",
  components: {
    WikiDetail,
  },
  props: {
    wikis: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tenants: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tab: null,
      selectedWikis: [],
    };
  },
  created() {
    this.tab = sessionStorage.getItem("wikiTab") || this.wikis[0]?.id;
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
  },
  methods: {
    handleTabChange(value) {
      sessionStorage.setItem("wikiTab", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs:not(:last-child) {
  border-right: 1px solid #f1f1f1;
}

.tabs-items {
  background-color: transparent;
}
</style>
