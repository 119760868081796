<template>
  <v-dialog
    v-model="dialogVisible"
    @click:outside="closeDialog"
    :persistent="loading"
    :max-width="modalData.type === 'collection' ? '600' : '1000'">
    <v-card class="py-6 px-10">
      <div class="d-flex justify-space-between align-center mb-8">
        <span v-html="modalTitle" class="modal-title text-h5 font-weight-medium" />
        <v-icon @click="closeDialog" color="black">mdi-close</v-icon>
      </div>
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-text-field
          v-model="positionNumber"
          :loading="loading"
          :key="'position-field-' + dialogVisible"
          label="Position"
          color="orange"
          type="number"
          outlined />
        <v-text-field
          v-model="payloadData.title"
          :rules="[...validator.required()]"
          :loading="loading"
          :key="'title-field-' + dialogVisible"
          label="Title"
          color="orange"
          clearable
          required
          outlined />
        <VueEditor
          v-if="modalData.type === 'item'"
          v-model="payloadData.content"
          :disabled="loading"
          placeholder="Content"
          :key="'vue-editor-' + dialogVisible" />
        <v-btn
          @click="modalAction"
          :loading="loading"
          color="success"
          :class="modalData.type === 'item' ? 'mt-8' : ''"
          large
          width="100%">
          Create
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { validator } from "@/util/helpers";
import { mapGetters } from "vuex";

export default {
  props: {
    modalOptions: {
      type: Object,
      default: () => ({
        type: "collection",
        isSub: false,
      }),
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({
        position: 0,
        title: "",
        content: "",
      }),
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      modalData: {},
      validator,
      dialogVisible: this.value,
      valid: true,
      payloadData: {},
    };
  },
  watch: {
    payload: {
      handler(newPayload) {
        this.payloadData = Object.assign({}, newPayload);
      },
      deep: true,
      immediate: true,
    },
    value(val) {
      this.dialogVisible = val;
      if (!val) {
        this.$nextTick(() => {
          this.payloadData = Object.assign({}, this.payload);
        });
      }
    },
    modalOptions: {
      handler(newOptions) {
        this.modalData = newOptions;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
    modalTitle() {
      const type = this.getType();
      return `Create ${type}`;
    },
    positionNumber: {
      get() {
        return this.payloadData.position;
      },
      set(value) {
        if (value === "") {
          this.payloadData.position = 0;
        } else {
          this.payloadData.position = Number(value);
        }
      },
    },
  },
  methods: {
    getType() {
      if (this.modalData.isSub && this.modalData.type === "collection") {
        return this.modalData.parentNodeTitle
          ? `Sub-Collection for <span class="font-weight-bold grey--text text--darken-2">${this.modalData.parentNodeTitle}</span>`
          : "Sub-Collection";
      } else if (this.modalData.type === "item") {
        return this.modalData.parentNodeTitle
          ? `Item in <span class="font-weight-bold grey--text text--darken-2">${this.modalData.parentNodeTitle}</span>`
          : "Item";
      } else {
        return this.modalData.type === "collection" ? "Collection" : "Item";
      }
    },
    clearModal() {
      this.payloadData = Object.assign({}, this.payload);
      this.$refs.form.reset();
    },
    closeDialog() {
      if (this.loading) return;
      this.$emit("close", false);
      this.clearModal();
    },
    modalAction() {
      if (this.$refs.form.validate()) {
        this.payloadData = {
          ...this.payloadData,
          ...this.modalData,
        };

        if (this.modalData.type === "item") {
          this.$emit("create", this.payloadData);
        } else {
          const { content, ...rest } = this.payloadData;
          this.$emit("create", rest);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
