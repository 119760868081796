<template>
  <div>
    <Header
      @dialog="handleDialog"
      @create="createWiki"
      :tenants="tenants"
      :createDialog="createDialog"
      :loading="loading" />
    <div class="mt-5">
      <Tabs :wikis="wikis" :loading="loading" :tenants="tenants" />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Tabs from "./components/Tabs.vue";
import Trainplanet from "@/util/trainplanet.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Wiki",
  components: {
    Header,
    Tabs,
  },
  data() {
    return {
      createDialog: false,
      loading: false,
      wikis: [],
    };
  },
  mounted() {
    this.listWikis();
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
  },
  methods: {
    handleDialog(val) {
      this.createDialog = val;
    },
    async createWiki(payload) {
      this.$store.dispatch("loading", true);
      this.loading = true;
      try {
        const createPayload = {
          ...payload,
          tenantIds: payload.selectedTenants,
        };
        delete createPayload.selectedTenants;
        await Trainplanet.createWiki(createPayload);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.loading = false;
        this.createDialog = false;
        this.$store.dispatch("loading", false);
        this.listWikis();
      }
    },
    async listWikis() {
      this.$store.dispatch("loading", true);
      this.loading = true;

      try {
        const { data } = await Trainplanet.listWikis();
        this.wikis = data;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.loading = false;
        this.$store.dispatch("loading", false);
      }
    },
  },
};
</script>
