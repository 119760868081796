<template>
  <div v-if="!pageLoading">
    <DetailHeader
      @edit="updateWiki"
      @delete="deleteWiki"
      @dialog="handleWikiDialog"
      :wiki="wikiData"
      :editDialog="editDialog"
      :loading="loading" />
    <DetailCollections
      @create="createNode"
      @delete="deleteNode"
      @dialog="handleNodeDialog"
      @update="updateNode"
      :nodes="wikiData.nodes"
      :nodeModal="nodeModal"
      :loading="loading" />
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api.js";
import DetailHeader from "./components/WikiDetail/DetailHeader.vue";
import DetailCollections from "./components/WikiDetail/DetailCollections";
import { mapGetters } from "vuex";

export default {
  name: "WikiDetail",
  components: { DetailHeader, DetailCollections },
  props: {
    wiki: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      editDialog: false,
      nodeModal: false,
      wikiData: {},
    };
  },
  watch: {
    wiki: {
      handler(val) {
        this.wikiData = val;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      pageLoading: "isLoading",
    }),
  },
  methods: {
    handleWikiDialog(value) {
      this.editDialog = value;
    },
    handleNodeDialog(value) {
      this.nodeModal = value;
    },
    async getWiki() {
      this.loading = true;
      try {
        const response = await Trainplanet.getWiki(this.wikiData.id);
        this.wikiData = response;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.loading = false;
      }
    },
    async updateWiki(payload) {
      this.$store.dispatch("loading", true);
      this.loading = true;
      const updatePayload = {
        ...payload,
        tenantIds: payload.selectedTenants,
      };
      delete updatePayload.selectedTenants;
      try {
        await Trainplanet.updateWiki(this.wikiData.id, updatePayload);
        window.location.reload();
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.editDialog = false;
        this.loading = false;
        this.$store.dispatch("loading", false);
      }
    },
    async deleteWiki() {
      this.$confirm(`Are you sure that you want to delete this wiki?`, {
        color: "primary",
        title: "Delete Wiki",
      }).then(async (confirmed) => {
        if (!confirmed) return;
        this.$store.dispatch("loading", true);
        this.loading = true;
        try {
          await Trainplanet.deleteWiki([this.wikiData.id]);
          window.location.reload();
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message || error.message);
        } finally {
          this.loading = false;
          this.$store.dispatch("loading", false);
        }
      });
    },
    async createNode(payload) {
      this.loading = true;
      const { isSub, purpose, ...createPayload } = payload;
      try {
        await Trainplanet.createNode(this.wikiData.id, createPayload);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.loading = false;
        this.nodeModal = false;
        this.getWiki();
      }
    },
    async updateNode(payload) {
      this.loading = true;
      const { nodeId, ...restPayload } = payload;

      try {
        await Trainplanet.updateNode(this.wikiData.id, nodeId, restPayload);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.loading = false;
        this.getWiki();
      }
    },
    async deleteNode(payload) {
      this.$confirm(`Are you sure that you want to delete this ${payload.type}?`, {
        color: "primary",
        title: `Delete ${payload.type.charAt(0).toUpperCase() + payload.type.slice(1)}`,
      }).then(async (confirmed) => {
        if (!confirmed) return;
        this.$store.dispatch("loading", true);
        this.loading = true;
        try {
          await Trainplanet.deleteNode(this.wikiData.id, payload.nodeId);
          await this.getWiki();
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message || error.message);
        } finally {
          this.loading = false;
          this.$store.dispatch("loading", false);
        }
      });
    },
  },
};
</script>
