var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    class: {
      'mb-5': _vm.isAdmin
    },
    attrs: {
      "loading": _vm.loading,
      "elevation": "2"
    }
  }, [!_vm.loading && _vm.wikis.length ? _c('v-tabs', {
    attrs: {
      "slider-color": "transparent",
      "color": "primary",
      "show-arrows": ""
    },
    on: {
      "change": _vm.handleTabChange
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.wikis, function (wiki) {
    return _c('v-tab', {
      key: wiki.id,
      staticClass: "tabs",
      attrs: {
        "href": `#${wiki.id}`
      }
    }, [_vm._v(" " + _vm._s(wiki.name) + " ")]);
  }), 1) : _vm._e(), !_vm.wikis.length && !_vm.loading ? _c('div', {
    staticClass: "text-center font-weight-bold py-5"
  }, [_vm._v("There is no wiki to show.")]) : _vm._e()], 1), _c('v-tabs-items', {
    staticClass: "tabs-items",
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.wikis, function (wiki) {
    return _c('v-tab-item', {
      key: wiki.id,
      staticClass: "px-2",
      attrs: {
        "value": wiki.id
      }
    }, [_c('WikiDetail', {
      attrs: {
        "wiki": wiki
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }