<template>
  <v-card class="mt-5 mb-5" :loading="loading" elevation="2">
    <div class="pa-5">
      <div v-if="isAdmin" :class="['d-flex', 'justify-sm-end', 'align-center', { 'mb-5': nodes.length }]">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="loading" color="success" v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus</v-icon>
              Create Content
            </v-btn>
          </template>
          <CreateDropdown @action="handleAction" :createOptions="createOptions" />
        </v-menu>
      </div>
      <div class="main-list">
        <CollectionList
          v-if="nodes.length"
          @create="handleAction"
          @delete="handleDelete"
          @save="handleSave"
          :nodes="nodes"
          :loading="loading" />
        <div v-else :class="['text-center', 'font-weight-bold', { 'pa-4': isAdmin }]">
          There is no content for this wiki to show.
        </div>
        <CollectionsModal
          @create="handleModalCreate"
          @close="handleDialog(false)"
          :value="nodeModal"
          :modalOptions="modalOptions"
          :loading="loading" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionList from "./CollectionList.vue";
import CollectionsModal from "./CollectionsModal.vue";
import CreateDropdown from "./CreateDropdown.vue";

export default {
  name: "DetailCollections",
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nodeModal: {
      type: Boolean,
      default: false,
    },
  },
  components: { CollectionList, CollectionsModal, CreateDropdown },
  data() {
    return {
      modalOptions: { type: "collection", isSub: false },
      modalPayload: {},
      createOptions: [
        {
          text: "Create Collection",
          icon: "mdi-plus-box-multiple",
          color: "accent",
          action: "collection",
        },
        {
          text: "Create Item",
          icon: "mdi-plus",
          color: "success",
          action: "item",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
  },
  methods: {
    handleAction(payload) {
      this.handleDialog(true);
      this.modalOptions = payload;
    },
    handleDialog(value) {
      this.$emit("dialog", value);
    },
    handleModalCreate(payload) {
      this.$emit("create", payload);
    },
    handleDelete(payload) {
      this.$emit("delete", payload);
    },
    handleSave(payload) {
      this.$emit("update", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  .main-list {
    overflow-x: auto;
  }
}
</style>
