<template>
  <v-list :class="['collections-list', `depth-${depth}`]" :expand="depth !== 0">
    <template v-for="(node, idx) in nodesWithEditing">
      <v-list-group
        v-if="node.type === 'collection'"
        :key="`collection-${node.id}`"
        :disabled="node.isEditing || (isEditingNodeLocal && depth === 0)"
        :ripple="!node.isEditing"
        :value="node.isExpanded"
        :class="['nested-list-group', `depth-${depth}`, { contentless: !node.subNodes.length }]">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="d-flex gap-10 font-weight-bold">
              <v-text-field
                v-if="node.isEditing"
                @input="updatePosition"
                :value="position"
                class="position-input"
                type="number"
                label="Position"
                outlined
                dense
                hide-details />
              <v-text-field
                @click.stop
                @input="updateTitle"
                :value="node.isEditing ? title : node.title"
                :readonly="!node.isEditing"
                :disabled="!node.isEditing"
                :class="['text-field', { 'title-view': !node.isEditing }]"
                :outlined="node.isEditing"
                label="Title"
                dense
                hide-details />
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="mr-0">
            <v-tooltip v-if="!node.isEditing" :content-class="!isEditingNodeLocal ? 'empty-tooltip' : ''" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-menu v-if="isAdmin && !node.isEditing" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="isEditingNodeLocal || loading" icon color="success" v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <CreateDropdown
                      @action="handleCreate(node.id, node.title, $event)"
                      :createOptions="createOptions"
                      isSub />
                  </v-menu>
                  <v-btn
                    v-if="isAdmin && !node.isEditing"
                    @click.stop="setEditState(node, true)"
                    :disabled="isEditingNodeLocal || loading"
                    color="third"
                    icon>
                    <v-icon size="20">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="isAdmin && !node.isEditing"
                    @click.stop="handleDelete(node.id, node.type)"
                    :disabled="isEditingNodeLocal || loading"
                    color="primary"
                    icon>
                    <v-icon size="20">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>You cannot perform other actions while editing a node</span>
            </v-tooltip>
            <v-btn v-if="node.isEditing" @click.stop="handleSave(node)" color="success" class="mr-2">
              <v-icon size="20" left>mdi-check</v-icon>
              Save
            </v-btn>
            <v-btn v-if="node.isEditing" @click.stop="setEditState(node, false)" color="primary">
              <v-icon size="20" left>mdi-close</v-icon>
              Cancel
            </v-btn>
          </v-list-item-icon>
        </template>
        <CollectionList
          v-if="node.subNodes && node.subNodes.length"
          @update:isEditingNode="handleIsEditingNodeUpdate"
          @create="$emit('create', $event)"
          @delete="$emit('delete', $event)"
          @save="$emit('save', $event)"
          :nodes="node.subNodes"
          :depth="depth + 1"
          :isEditingNode="isEditingNodeLocal"
          :loading="loading"
          class="nested-list" />
      </v-list-group>
      <v-list-group
        v-else-if="node.type === 'item'"
        @click="handleFirstNode(node)"
        :key="`item-${node.id}${depth === 0 && idx === 0 ? '-' + firstNodeExpanded : ''}`"
        :value="node.isExpanded"
        :disabled="node.isEditing || (isEditingNodeLocal && depth === 0)"
        :ripple="!node.isEditing"
        :class="['nested-list-item', `depth-${depth}`, { contentless: !node.content && !node.isEditing }]">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="d-flex gap-10 font-weight-bold">
              <v-text-field
                v-if="node.isEditing"
                @input="updatePosition"
                :value="position"
                class="position-input"
                type="number"
                label="Position"
                outlined
                dense
                hide-details />
              <v-text-field
                @click.stop
                @input="updateTitle"
                :value="node.isEditing ? title : node.title"
                :readonly="!node.isEditing"
                :disabled="!node.isEditing"
                :class="['text-field', { 'title-view': !node.isEditing }]"
                :outlined="node.isEditing"
                label="Title"
                dense
                hide-details />
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="mr-0">
            <v-tooltip v-if="!node.isEditing" :content-class="!isEditingNodeLocal ? 'empty-tooltip' : ''" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn v-if="isAdmin && !node.isEditing" :disabled="isEditingNodeLocal || loading" color="third" icon>
                    <v-icon @click.stop="setEditState(node, true)" size="20">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="isAdmin && !node.isEditing"
                    :disabled="isEditingNodeLocal || loading"
                    color="primary"
                    icon>
                    <v-icon @click.stop="handleDelete(node.id, node.type)" size="20">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>You cannot perform other actions while editing a node</span>
            </v-tooltip>
            <v-btn v-if="node.isEditing" @click.stop="handleSave(node)" color="success" class="mr-2">
              <v-icon size="20" left>mdi-check</v-icon>
              Save
            </v-btn>
            <v-btn v-if="node.isEditing" @click.stop="setEditState(node, false)" color="primary">
              <v-icon size="20" left>mdi-close</v-icon>
              Cancel
            </v-btn>
          </v-list-item-icon>
        </template>
        <v-list-item v-if="node.content || node.isEditing">
          <v-list-item-content>
            <VueEditor
              :value="editorContent(node)"
              @input="updateContent"
              :class="{ 'view-state': !node.isEditing }"
              :disabled="!node.isEditing" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import CollectionList from "./CollectionList.vue";
import CreateDropdown from "../DetailCollections/CreateDropdown.vue";

export default {
  name: "CollectionList",
  props: {
    nodes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    isEditingNode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CollectionList,
    CreateDropdown,
    VueEditor,
  },
  data() {
    return {
      createOptions: [
        {
          text: "Create Sub-Collection",
          icon: "mdi-plus-box-multiple",
          color: "accent",
          action: "collection",
        },
        {
          text: "Create Item",
          icon: "mdi-plus",
          color: "success",
          action: "item",
        },
      ],
      position: 0,
      title: "",
      content: "",
      isEditingNodeLocal: this.isEditingNode,
      firstNodeExpanded: true,
    };
  },
  watch: {
    isEditingNode(newValue) {
      this.isEditingNodeLocal = newValue;
    },
    isEditingNodeLocal(newValue) {
      this.$emit("update:isEditingNode", newValue);
    },
  },
  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    isAdmin() {
      return this.userRoles.includes("Role.Admin") || this.userRoles.includes("Role.Admin.BackOffice");
    },
    nodesWithEditing() {
      return this.nodes.map((node, index) => ({
        ...node,
        isEditing: false,
        isExpanded: !!(index === 0 && this.depth === 0),
      }));
    },
    firstNodeAtRoot() {
      if (this.depth === 0 && this.nodesWithEditing.length > 0) return this.nodesWithEditing[0];
    },
  },
  methods: {
    editorContent(node) {
      return node.isEditing ? this.content : node.content;
    },
    updatePosition(newPosition) {
      this.position = parseFloat(newPosition);
    },
    updateTitle(newTitle) {
      this.title = newTitle;
    },
    updateContent(newContent) {
      this.content = newContent;
    },
    handleIsEditingNodeUpdate(newValue) {
      this.isEditingNodeLocal = newValue;
    },
    handleCreate(nodeId, nodeTitle, payload) {
      payload = { ...payload, parentNodeId: nodeId, parentNodeTitle: nodeTitle };
      this.$emit("create", payload);
    },
    handleDelete(nodeId, type) {
      this.$emit("delete", { nodeId, type });
    },
    handleSave(node) {
      const savePayload = {
        nodeId: node.id,
        position: this.position ? this.position : 0,
        title: this.title,
        content: this.content,
      };
      this.$emit("save", savePayload);
      this.setEditState(node, false);
    },
    setEditState(node, val) {
      if (this.firstNodeExpanded) this.handleFirstNode(this.firstNodeAtRoot);
      node.isEditing = val;
      this.isEditingNodeLocal = val;
      if (node.type === "item") node.isExpanded = val;
      if (val) {
        this.position = node.position;
        this.title = node.title;
        this.content = node.content;
      } else {
        this.clearPayload();
      }
      this.$forceUpdate();
    },
    handleFirstNode(node) {
      if (!this.firstNodeExpanded || this.depth !== 0) return;
      node.isExpanded = !node.isExpanded;
      this.firstNodeExpanded = false;
    },
    clearPayload() {
      this.position = 0;
      this.title = "";
      this.content = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.collections-list.depth-0 {
  padding: 0;
}

.collections-list:not(.depth-0) {
  margin-left: 30px;
  border-left: 1px solid #ccc;
  border-radius: 0;
}

.collections-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 0;
  border-radius: 8px;
}

// List styles
.nested-list-group:not(.depth-0),
.nested-list-item:not(.depth-0) {
  margin-left: 30px;
}

::v-deep .nested-list-item:not(.contentless) {
  .v-list-group__items .v-list-item {
    background: #fcfcfc !important;
    border-radius: 0 0 8px 8px !important;
  }

  .v-list-item--active {
    border-radius: 8px 8px 0 0 !important;
    border-bottom: none;
  }
}

::v-deep .v-list-group__header__append-icon {
  order: -1;
  min-width: 0 !important;
}

::v-deep .contentless .v-list-item .v-list-group__header__append-icon {
  display: none;
}

::v-deep .contentless .v-list-group__header {
  cursor: auto;
}

::v-deep .v-list-item {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
}

::v-deep .v-list-group__header__append-icon {
  margin: 0 25px 0 2px !important;
}

// Input styles
::v-deep .text-field {
  pointer-events: auto;
}

::v-deep .v-input--hide-details {
  padding-top: 0;
  margin-top: 0;
}

::v-deep .v-text-field__slot input {
  color: #636363;
}

::v-deep .v-list-item__title:not(:has(.title-view)) {
  overflow: visible;
}

.position-input {
  max-width: 100px;
}

// VueEditor styles
::v-deep .view-state .ql-toolbar {
  display: none;
}

::v-deep .view-state .ql-container {
  border: none;

  .ql-editor {
    padding: 0;
    min-height: 0;
  }
}

::v-deep .ql-container.ql-disabled, .quillWrapper:has(>.ql-container.ql-disabled) {
  background-color: transparent !important;
}

@media (max-width: 500px) {
  .collections-list {
    min-width: 500px;
    gap: 5px;
  }

  .nested-list-group:not(.depth-0),
  .nested-list-item:not(.depth-0) {
    margin-left: 20px;
    width: calc(100% - 50px);
  }
}

.title-view {
  pointer-events: none;
}

.empty-tooltip {
  display: none !important;
}
</style>
